<template>
  <section class="content">
    <div class="card card-info">
      <!-- <div class="card-header">
        <h3 class="card-title">Daftar Topup Saldo</h3>
      </div> -->
      <div class="card-body">
        <div class="row">
        <div class="col-12 d-flex justify-content-end mb-2">
            <div class="row form-horizontal justify-content-end">
                <div class="col-auto col-xs-4">
                    <label class="control-label my-2 ml-2">
                        <h6>Filter Pencarian</h6>
                    </label>
                </div>
                <div class="col-auto col-xs-4">
                  <select class="form-control" @change="bankChange($event)">
                      <option value="#" disabled selected>Pilih Bank</option>
                      <option v-for="bank in totalBank" v-bind:key="bank.rekening_id" v-bind:value="bank.rekening_id">
                          {{ bank.label }}
                      </option>
                      <option value="0">Semua</option>
                  </select>
                </div>
                <div class="col-auto col-xs-4">
                    <div class="btn-group" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i
                        class="fa fa-sync-alt mr-1"></i>Refresh</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover" ref="tblsaldoapprove">
            <thead>
              <tr>
                <th>ID SHIPPER</th>
                <th>SHIPPER</th>
                <th>NILAI TOPUP</th>
                <th>KODE UNIK</th>
                <th>NILAI TRANSFER</th>
                <th>TANGGAL</th>
                <th>JENIS BANK</th>
                <th>INVOICE ID</th>
                <th>STATUS</th>
                <th>NOTE</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  nama: "Daftar Topup Saldo Approval",
  data() {
    return {
      errors: [],
      totalBank: [],
      method: "",
      form: {
        action: "",
      },
      filter: {},
      //   saldo: {},
      //   total: "",
      roles: "",
      role_id: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch("/payment/saldo/bank")
      .then((res) => {
          if (res.status === 201) {} else if (res.status === 400) {}
          return res.json();
      })
      .then((js) => {
          this.totalBank = js.data;
      });
  },
  methods: {
    onReload() {
			this.tbl.api().ajax.reload();
		},
    bankChange(event) {
        let id = event.target.value;
        this.filter.rekening_id = id;
        this.onReload();
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        this.form.action = e.target.dataset.action;
        var data = Object.keys(this.form)
          .map(
            (key) =>
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent(this.form[key])
          )
          .join("&");
        if (this.form.action == "confirm") {
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/payment/admin/approval/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        } else if (this.form.action == "tolak") {
          Swal.fire({
            title: "Tolak?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/payment/admin/approval/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        // if (e.target.dataset.action == "confirm") {
        // }
        // console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    this.tbl = createTable(e.tblsaldoapprove, {
        title: "",
        roles: self.$route.params.roles,
        selectedRowClass: "",
        serverSide: true,
        frame: false,
        displayLength: 10,
        lengthChange: true,
        processing: true,
        toolbarEl: e.toolbar,
        order: [[ 4, "desc" ]],
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        ajax: "/payment/admin/topup",
        columns: [
        { data: "shipper_code" },
        { data: "shipper_name" },
        { data: "amount", render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
        }, },
        { data: "unique_code", render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
        }, },
        { data: "debit", render: function (data, type, row, meta) {
          return "Rp " + formatCurrency(data);
            // let nilai = 0;
            // nilai = row.debit+row.unique_code;
        }, },
        { data: "created_on",
        render: function (data, type, row, meta) {
            return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "bank", width: "90px",
        render: function (data, type, row, meta) {
            if (data !== '') {
                return `<span>${row.bank} (${row.code}) <br> ` + (row.payment_method != 'winpay' ? row.rekening + ` <br> ` : '') + (row.payment_method != 'winpay' ? row.atas_nama : "Payment Gateway") + `</span>`;
            } else {
                return "";
            }
        } },
        { data: "invoice_code" },
        { data: "status", render: function (data, type, row, meta) {
            let status = '';
            if(row.active < 1)
            {
              return `<span class="badge badge-danger">DITOLAK</span>`;
            } else {
              if (row.status == 0) {
                  status = `<span class="badge badge-warning">PENDING</span>`;
              } else {
                  status = `<span class="badge badge-success">SUKSES</span>`;
              }
            }
            return status;
        } },
        { data: "note",
        sortable: false,
        render: function (data, type, row, meta) {
            if (row.status == 1) {
                if(row.approved_by == 0)
                {
                    return 'Approved By System';
                } else {
                    return `Approved By ${row.display_name}`;
                }
            } else {
                return '';
            }
        } },
        { data: "action", sortable: false, render: function (data, type, row, meta) {
            let act = "";

            if (row.status > 0 || row.active < 1) {
                act = "";
            } else {
                if (row.payment_method == 'winpay') {
                    act = "Can't approve by manual";
                } else {
                    act = `<div class="btn-group">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Klik
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                    <button type="button" class="btn btn-sm btn-success dropdown-item" data-action="confirm" data-active="1" data-id="` +
                            row.invoice_code +
                            `"> Konfirmasi </button>
                    <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="tolak" data-active="0" data-id="` +
                            row.invoice_code +
                            `"> Tolak </button>
                    </div>
                </div>`;
                }
            }

            return act;
        } },
        ],
        filterBy: [0, 1, 2, 4, 7],
        paramData: function (d) {
          var filter = JSON.parse(JSON.stringify(self.filter));
          for (const key in filter) {
              d[key] = filter[key] === false ? 0 : filter[key];
          }
        },
        rowCallback: function (row, data) {
        },
				initComplete: function () {
					$('.loading-overlay').removeClass('show');
				},
    });
    },
};
</script>
<style scoped>
</style>